import { useLocation } from "@remix-run/react";
/*
idea to pass already loaded data from one route to another. This will reduce the time to wait for first content paint.
i.e. pass data (Show) on navigation from a show teaser to the showpage. Load more detailed content lazy (ShowDetail).
 */
export const useRouteState = () => {
  const location = useLocation();
  const {
    state
  } = location;
  if (state?.media) {
    state.squeezedVideoDetail = squeezeToVideoDetail(state.media);
  }
  if (state?.show) {
    state.squeezedShowDetail = squeezeToShowDetail(state.show);
  }
  return state ?? {};
};
const squeezeToVideoDetail = media => ({
  ...media,
  isSegment: false,
  episodeLinks: [],
  multiAudioAvailable: false,
  audioDescriptionAvailable: false,
  embedAllowed: false,
  allowIndexing: false,
  hasSegments: false
});
const squeezeToShowDetail = show => ({
  ...show,
  links: [],
  allowIndexing: false,
  topicUrns: [],
  programList: []
});